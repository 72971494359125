
import {
	computed,
	defineComponent,
	onMounted,
	reactive,
	ref,
	toRefs,
} from 'vue'
import { useRouter } from 'vue-router'
import { useBreakpoints } from '@vueuse/core'
import { ElNotification, ElMessageBox } from 'element-plus'
import { deleteBankIdShop, deleteShop, getAddressList } from '@/api/shop.api'
import {
	addAddress,
	AddressPayload,
	defaultAddress,
	deleteAddress,
	editAddress,
} from '@/api/address/address.api'
import AddressSystem from '@/components/core/AddressSystem.vue'
import { concatAddress } from '@/utils/address'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { IAddressShopStore } from '@/pinia/shop/types'

type Address = [];

export default defineComponent({
	name: 'ShopAddress',
	components: {
		AddressSystem,
	},
	setup() {
		const router = useRouter()
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const storePermissionShop = computed(
			() => userStore.getSettingShopPermission
		)
		const isFetchInitialData = ref<boolean>(true)
		const isShowAddressDialog = ref<boolean>(false)
		const breakpoints = useBreakpoints({
			tablet: 1024,
		})
		const isSmallerThanTablet = breakpoints.isSmaller('tablet')
		const shopAddressData = reactive({
			address: [] as IAddressShopStore[],
		})
		const selectedAddress = computed(() =>
			shopAddressData.address.find((addr) => addr['isSelected'])
		)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const initialAddressForm = {
			isShow: false,
			addressId: null, // Only when isEdit Mode
			shopId: '',
			desc1: '',
			provinceCode: null,
			districtCode: null,
			subDistrictCode: null,
			zipCode: null,
		}

		const addressForm = ref({ ...initialAddressForm })

		const setEditAddressDetail = (add: any) => {
			//
			isShowAddressDialog.value = true
			addressForm.value.addressId = add._id
			addressForm.value.shopId = add.shopId
			addressForm.value.desc1 = add.desc1
			addressForm.value.provinceCode = add.provinceCode
			addressForm.value.districtCode = add.districtCode
			addressForm.value.subDistrictCode = add.subDistrictCode
			addressForm.value.zipCode = add.zipCode
		}

		const onDeleteAddress = (addressId: string) => {
			ElMessageBox.confirm('ยืนยันลบที่อยู่?', {
				confirmButtonText: 'ยืนยัน',
				cancelButtonText: 'ยกเลิก',
				type: 'error',
				showClose: !isReactNativeWebview.value
			}).then(async () => {
				await deleteAddress(addressId)
				ElNotification({
					title: 'สำเร็จ',
					message: 'ลบที่อยู่สำเร็จ',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
				fetchAddress()
				addressForm.value.isShow = false
			})
		}

		const onDeleteBank = (bankId: string) => {
			//
			ElMessageBox.confirm('ยืนยันลบ ธนาคาร?', {
				confirmButtonText: 'ยืนยัน',
				cancelButtonText: 'ยกเลิก',
				type: 'error',
				showClose: !isReactNativeWebview.value
			}).then(async () => {
				await deleteBankIdShop(bankId)
				ElNotification({
					title: 'สำเร็จ',
					message: 'ลบธนาคารสำเร็จ',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
				fetchAddress()
			})
		}

		const onSubmitAddress = async (): Promise<any> => {
			const payload: AddressPayload = {
				desc1: addressForm.value.desc1,
				shopId: shopStore.selectedShopId,
				addressId: addressForm.value.addressId,
				provinceCode: addressForm.value.provinceCode,
				districtCode: addressForm.value.districtCode,
				subDistrictCode: addressForm.value.subDistrictCode,
				zipCode: addressForm.value.zipCode,
			}
			//
			if (addressForm.value.addressId) {
				// Submit shop id edit mode
				await editAddress(payload)
			} else {
				// Add New Address
				await addAddress(payload)
			}
			isShowAddressDialog.value = false
			fetchAddress()

			// clear with initial Data
			addressForm.value = { ...initialAddressForm }
		}

		const onCloseAddressDialog = () => {
			addressForm.value.isShow = false
			addressForm.value = { ...initialAddressForm }
		}

		const fetchAddress = () => {
			getAddressList(shopStore.selectedShopId as string)
				.then(({ data: { address } }) => {
					shopAddressData.address = address

					// อัพเดท persisted address ที่อยู่ใน store
					// เพื่อที่หน้า create order จะได้ไม่ต้อง fetch หรือ มี loading
					// ให้ใช้จากตัว persisted ได้เลย
					shopStore.updateShopStoreAddress(address)
				})
				.finally(() => (isFetchInitialData.value = false))
		}

		const setDefaultAddress = async (address: any) => {
			// Set imdiatly
			// remove All other isSelected
			(selectedAddress.value as any).isSelected = false
			address.isSelected = true

			const payload = {
				addressId: address._id,
				shopId: shopStore.selectedShopId as string,
			}
			await defaultAddress(payload)
			await fetchAddress()
		}

		const onDeleteShop = () => {
			//
			ElMessageBox.prompt('กรุณาพิมพ์  "delete shop"', 'ยืนยันลบร้านค้า', {
				confirmButtonText: 'ยืนยัน',
				cancelButtonText: 'ยกเลิก',
				type: 'error',
				showClose: !isReactNativeWebview.value,
				inputErrorMessage: 'กรุณาระบุคำว่า delete shop เพื่อยืนยันการลบ',
				inputValidator: (value): boolean => {
					return value === 'delete shop'
				},
			}).then(async () => {
				//
				const shopId = shopStore.selectedShopId as string
				await deleteShop(shopId)

				// Clear Selected Data then push to select shop page
				shopStore.updateSelectedShop(null)
				
				router.push('/shop')
			})
		}

		onMounted(() => {
			fetchAddress()
		})

		return {
			...toRefs(shopAddressData),
			isShowAddressDialog,
			addressForm,
			setEditAddressDetail,
			onSubmitAddress,
			isFetchInitialData,
			isSmallerThanTablet,
			onCloseAddressDialog,
			onDeleteAddress,
			setDefaultAddress,
			selectedAddress,
			onDeleteBank,
			onDeleteShop,
			concatAddress,
			storePermissionShop,
			isReactNativeWebview,
		}
	},
})
