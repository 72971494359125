import { AxiosResponse } from 'axios'
import axios from '../instance'

export interface AddressPayload {
    shopId?: string|null;
    addressId?: string|null;
    desc1: string;
    provinceCode: number|null;
    districtCode: number|null;
    subDistrictCode: number|null;
    zipCode: number|null;
}

export interface AddressI {
	districtCode: number;
	districtName: string;
	provinceCode: number;
	provinceName: string;
	subDistrictCode: number;
	subDistrictName: string;
	zipCode: number[];
}

export type ProvinceI = Pick<AddressI, 'provinceCode'|'provinceName'> 
export type DistrictI = Pick<AddressI, 'districtCode'|'districtName'>
export type SubDistrictI = Pick<AddressI, 'subDistrictCode'|'subDistrictName'|'zipCode'>
export type ZipCodeI = string[]

export const addAddress = async (payload: AddressPayload): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.post('/shop/address/add', payload)
		return response as any
	} catch (error) {
		return Promise.reject(error)
	}
}

export const editAddress = async (payload: AddressPayload): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.put('/shop/address/edit', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteAddress = async (addressId: string): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.delete(`/shop/address/remove/${addressId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface DefaultAddressPayload {
    addressId: string;
    shopId: string;
}

export const defaultAddress = async (payload: DefaultAddressPayload): Promise<AxiosResponse<any>> => {
	try {
		const response = await axios.post('/shop/address/select', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
